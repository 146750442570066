import React, { useState, useEffect } from "react";
import "../CSS/Intro.css";
import portfoliologo from "../Images/portfoliologo.svg";
import { useNavigate } from "react-router-dom";
import sec1 from "../Images/sec1.svg";
import sec2 from "../Images/sec2.svg";
import sec3 from "../Images/sec3.svg";
import whiteLogo from "../Images/whiteLogo.svg";
import { Modal, Button } from "react-bootstrap";
import Privacypolicy from "./Privacypolicy";
import { baseURL } from "./Apis";
import whitelogo from "../Images/White logo.svg";
import "@fontsource/bebas-neue";
import "@fontsource/work-sans";
import img1 from "../Images/photo1.svg";
import photo4 from "../Images/photo4.svg";
import upload from "../Images/upload.svg";
import chat from "../Images/chat.svg";
import ans from "../Images/ans.svg";
import mask1 from "../Images/mask1.svg";
import mask2 from "../Images/mask2.svg";
import eye from "../Images/eye.svg";
import box from "../Images/box.svg";
import sparkle from "../Images/sparkle.svg";
import swatch from "../Images/swatch.svg";
import card1 from "../Images/card1.svg";
import card2 from "../Images/card2.svg";
import card3 from "../Images/card3.svg";
import card4 from "../Images/card4.svg";
import card5 from "../Images/card5.svg";
import card6 from "../Images/card6.svg";
import card7 from "../Images/card7.svg";
import hand2 from "../Images/hands2.svg";
import circles from "../Images/circles.svg";
import linkedin from "../Images/linkedinicon.svg";
import loginicon from "../Images/loginicon.svg";
import player from "../Images/Player.svg";
import ReactPlayer from "react-player";
import Snackbar from '@mui/material/Snackbar';
import { LuRefreshCcw } from "react-icons/lu";


const Introduction = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const onClickLogin = () => {
    navigate("/login");
  };

  const onClickRegister = () => {
    navigate("/register");
  };
  const [formValues, setFormValues]: any = useState({
    Company: "",
    'First Name': "",
    'Last Name': "",
    Designation: "",
    Email: "",
    Phone:'',
    Description:''
  });
  const initialValues = {
    Company: "",
    'First Name': "",
    'Last Name': "",
    Designation: "",
    Email: "",
    Phone: "",
    Description: ""
  };
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState<string>("");
  const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [disableButton, setdisableButton] = React.useState(false);
  const [captcha, setCaptcha] = useState<string>("");
  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues:any) => ({
        ...prevValues,
        [name]: value,
    }));
};



useEffect(() => {
  const loadZohoScript = () => {
    var $zoho = (window as any).$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: 'siq35e638448267c14646305d5305ef278a79d151f4fa755ac8add354b00d63568bb7aad3912cdc067818239ad075c7eabb',
      values: {},
      ready: function () {},
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zoho.com/widget";
    const t:any = document.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(script, t);

    // After script loads, track the visitor and fetch the unique visitor ID
    script.onload = () => {
      if ($zoho && $zoho.salesiq && $zoho.salesiq.visitor) {
        // Set unique visitor ID
        const uniqueVisitorId = $zoho.salesiq.visitor.uniqueid();
        setUniqueId(uniqueVisitorId); // Set unique ID in state
      }
    };
  };

 // loadZohoScript();
}, []);



  // Handle form submission
  const handleSubmit = async(e: any) => {
    e.preventDefault();
    if (!checkMandatory()) return;
    const form = e.target;
    const formData = new FormData(form);
    const dataForAPi: any = {
      company: formValues.Company,
      title: formValues.Designation,
      first_name: formValues['First Name'],
      last_name: formValues['Last Name'],
      email: formValues.Email,
      phone_no: formValues.Phone,
      description: formValues.Description,
    };
    setdisableButton(true);
    try {
      const response = await fetch(baseURL+'send_lead_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers required by the API
      },
        body: JSON.stringify(dataForAPi),
      });
      if (response.ok) {
        setFormValues(initialValues);
        setdisableButton(false);
        setDigContent(
          <>
            <p style={{ marginBottom: '5px' }}>
              <span 
                id="successicon" 
                style={{
                  background: 'url(https://static.zohocdn.com/crm/CRMClient/images/successalert_a8be6d090fa10db5a38d8f87bf194f7d_.svg) no-repeat 0 0',
                  width: '22px',
                  height: '22px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              ></span>&nbsp;&nbsp;
              <b>Thank you for submitting your response.</b>
            </p>
            <p>
              <span>Your response has been submitted successfully. We will reach out to you soon.</span>
            </p>
          </>
        );
        
        setModalShow(true)
      } else {
        setdisableButton(false);
        setDigContent(`Form submission failed. Please try again.`);
        setModalShow(true)
      }
    } catch (error) {
      setdisableButton(false);
      setDigContent(`Form submission failed. Please try again.`);
      setModalShow(true)
    } 

   /*  try {
      const response = await fetch('https://crm.zoho.com/crm/WebToLeadForm', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setDigContent(`Thank you for submitting your response. Your response has been submitted successfully. We will reach out to you soon.`);
        setModalShow(true)
      } else {
        setDigContent(`Form submission failed. Please try again.`);
        setModalShow(true)
      }
    } catch (error) {
      setDigContent(`Form submission failed. Please try again.`);
      setModalShow(true)
    }  */
  
    /* const submitButton = document.querySelector('.formsubmit');
    if (submitButton) {
      submitButton.setAttribute('disabled', 'true');
    }  */
    //window.addEventListener('focus', historyBack); */
    /* try {
      if (window && (window as any).$zoho && (window as any).$zoho.salesiq) {
        const $zoho = (window as any).$zoho;
        if (formValues.Email) {
          $zoho.salesiq.visitor.email(formValues.Email);
        }
      }
    } catch (error) {
      
    } */
  };

  const historyBack = () =>{
    const submitButton = document.querySelector('.formsubmit');
    if (submitButton) {
      submitButton.removeAttribute('disabled');
    }
   reloadCaptcha(); 
   window.removeEventListener('focus', historyBack); 
  }
  
  const checkMandatory = () => {
    const mndFields: any = [
      "Company",
      "First Name",
      "Last Name",
      "Designation",
      "Email",
    ];
    for (let i = 0; i < mndFields.length; i++) {
      const fieldValue: any = formValues[mndFields[i]];
      if (!fieldValue.trim()) {
        setDigContent( <>
          Fields marked (<span style={{ color: "#08B180" }}>*</span>) are mandatory.
        </>);
        setModalShow(true)
        return false;
      }
    }
    return validateEmail();
  };


  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formValues.Email)) {
      setDigContent("Please enter a valid email.");
      setModalShow(true)
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptcha(e.target.value);
  };

  const reloadCaptcha = () => {
    var captcha:any = document.getElementById('captchaImage'); 
    if(captcha.src.indexOf('&d') !== -1) 
    { 
        captcha.src = captcha.src.substring(0, captcha.src.indexOf('&d')) + '&d' + new Date().getTime(); 
    } else 
    { 
        captcha.src = captcha.src + '&d' + new Date().getTime(); 
    } 
  };

  const handleStartClick = () => {
    const element = document.getElementById("zohoform");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <div
      style={{
        display: "flex",
        background: "#161616",
        flexShrink: 0,
        height: "76px",
        alignItems: "center",
        paddingLeft: "64px",
        paddingRight: "64px",
      }}
    >
      {/* Left-aligned logo */}
      <img
        src={whitelogo}
        alt=""
        style={{ height: "21px", textAlign: "center" }}
      />

      {/* Right-aligned login icon and button */}
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "18px",
            color: "#FFF",
            letterSpacing: "-5%",
            cursor: "pointer",
          }}
          onClick={onClickLogin}
        >
          <img
            src={loginicon}
            alt=""
            style={{ height: "21px", marginRight: "8px" }} // Margin for spacing between icon and text
          />
          Login
        </div>
        <button
          className="btn"
          style={{
            background: "#161616",
            color: "#FFF",
            border: "1px solid #525252",
            fontFamily: "Work Sans",
            lineHeight: "18px",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
            width: "128px",
            height: "36px",
            borderRadius: "6px",
          }}
          onClick={handleStartClick}
        >
          Get Started
        </button>
      </div>
    </div>

    <div
      className="sec1"
      style={{
        height: "100%"
      }}
    >
      <div
        style={{
          fontFamily: "Bebas Neue",
          lineHeight: "96px",
          fontSize: "92px",
          fontWeight: 700,
          color: "#fff",
          paddingTop: "100px",
        }}
      >
        <div style={{ color: "#fff" }}>FUTURE PROOF Your Commercial</div>
        <div style={{ color: "#fff" }}>
          Real Estate with{" "}
          <span style={{ color: "#08B180" }}>Portfolio Insights</span>
        </div>
      </div>
      <div
        style={{
          fontFamily: "Work Sans",
          lineHeight: "22px",
          fontSize: "18px",
          fontWeight: 400,
          color: "#fff",
          paddingTop: "12px",
          letterSpacing: "-0.050em",
        }}
      >
        Minimize Risk and Maximize ROI with AI-Powered Analytics
      </div>
      <div style={{ marginTop: "50px" }}>
        <button
          className="btn"
          style={{
            background: "#0A78FF",
            color: "#FFF",
            fontFamily: "Work Sans",
            lineHeight: "18px",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
            width: "150px",
            height: "49px",
          }}
          onClick={handleStartClick}
        >
          Get Started
        </button>
      </div>
      <img style={{ width:'100%' }} src={img1}></img>
    </div>
    <div
      className="sec2"
      style={{
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom:'100px'
      }}
    >
      <div
        style={{
          paddingTop: "120px",
          fontFamily: "Work Sans",
          lineHeight: "56px",
          fontSize: "42px",
          fontWeight: 400,
          color: "#9D9D9D",
          letterSpacing: "-0.075em",
        }}
      >
        80% of Workplaces Around the World Are Now Hybrid.
      </div>
      <div>
        <button
          className="btn rounded-pill px-2"
          style={{
            background: "#0A78FF",
            fontFamily: "Work Sans",
            lineHeight: "56px",
            fontSize: "42px",
            fontWeight: 400,
            color: "#fff",
            letterSpacing: "-0.075em",
            margin: 0,
            padding: 0,
          }}
        >
          Is Your Business Strategy Keeping Pace?
        </button>
      </div>
      <div
        style={{
          paddingTop: "32px",
          fontFamily: "Work Sans",
          lineHeight: "26px",
          fontSize: "18px",
          fontWeight: 400,
          color: "#9D9D9D",
          letterSpacing: "-0.075em",
        }}
      >
        With 24x7 access to property data, actionable insights, and powerful
        analytics, Portfolio One empowers
      </div>
      <div
        style={{
          fontFamily: "Work Sans",
          lineHeight: "26px",
          fontSize: "18px",
          fontWeight: 400,
          color: "#9D9D9D",
          letterSpacing: "-0.075em",
        }}
      >
        Senior Leaders to make informed decisions that drive efficiency,
        reduce costs, and 3x employee experience.
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
        }}
      >
        <div style={{ width: "840px", height: "560px" }}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=qjVdpuVS4A8"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </div>
    </div>
    <div className="sec3" style={{ height: "100%", paddingBottom:'100px' }}>
      <div className="col-12 row d-flex justify-content-center">
        <div className="col-4 d-flex justify-content-center">
          <img
            src={photo4}
            alt=""
            style={{ paddingTop: "130px", height: "800px", paddingLeft:'200px' }}
          />
        </div>
        <div
          className="offset-1 col-5"
          style={{ paddingTop: "256px", textAlign: "left" }}
        >
          <div
            style={{
              fontFamily: "Bebas Neue",
              lineHeight: "64px",
              fontSize: "60px",
              fontWeight: 700,
              background: "#0A78FF",
              color: "#fff",
              display: "inline-block",
            }}
          >
            Meet your AI-powered
          </div>
          <div
            style={{
              fontFamily: "Bebas Neue",
              lineHeight: "64px",
              fontSize: "60px",
              fontWeight: 700,
              color: "#fff",
            }}
          >
            real estate portfolio assistant
          </div>
          <div
            className="greenBackgroundTransperant px-2"
            style={{
              display: "inline-block",
              padding: "0 4px",
              marginTop: "40px",
              color: "#08B180",
              fontFamily: "Work Sans",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "26px",
              letterSpacing: "-0.050em",
            }}
          >
            <img src={sparkle} alt=""></img> Introducing Portfolio One’s
            AI-powered Chatbot
          </div>
          <div
            className=""
            style={{
              display: "inline-block",
              marginTop: "40px",
              color: "#fff",
              fontFamily: "Work Sans",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "26px",
              letterSpacing: "-0.050em",
            }}
          >
            Your go-to resource for real-time insights into physical asset
            usage, resource allocation, and more. Just ask a question, and the
            chatbot will provide accurate and informative answers based on
            your previously uploaded data. 
          </div>
        </div>
      </div>
   
      <span
        className="greenBackgroundTransperant px-2"
        style={{
          marginTop: "40px",
          color: "#08B180",
          fontFamily: "Work Sans",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "26px",
          letterSpacing: "-0.050em",
          display:
            "inline-block" /* Ensures background applies to text only */,
          padding:
            "0 4px" /* Adds padding for a cleaner appearance around the text */,
        }}
      >
        <img src={sparkle} alt=""></img> How to use the AI-powered Chatbot?
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          paddingTop: "30px",
          margin:'10px'
        }}
      >
        <div className="card cardType1">
          <div className="card-body">
            <div className="col-12 d-flex justify-content-center">
              <div className="col-3 d-flex justify-content-center align-item-center">
                <img src={upload} alt="" style={{ width: "85px" }}></img>
              </div>

              <div className="offset-1 col-6 d-flex flex-column align-items-start">
                <div className="step">Step 1</div>
                <div className="stepName">Upload</div>
                <hr className="hrProperty" />
                <div className="stepDes">
                  Upload Your Documents in the Portfolio One Server
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card cardType1">
          <div className="card-body">
            <div className="col-12 d-flex justify-content-center">
              <div className="col-3 d-flex justify-content-center">
                <img src={chat} alt="" style={{ width: "85px" }}></img>
              </div>
              <div className="offset-1 col-6 d-flex flex-column align-items-start">
                <div className="step">Step 2</div>
                <div className="stepName">Chat</div>
                <hr className="hrProperty" />
                <div className="stepDes">
                  Speak or Type Your Question into The AI Chatbot
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card cardType1">
          <div className="card-body">
            <div className="col-12 d-flex justify-content-center">
              <div className="col-3 d-flex justify-content-center">
                <img src={ans} alt="" style={{ width: "85px" }}></img>
              </div>
              <div className="offset-1 col-6 d-flex flex-column align-items-start">
                <div className="step">Step 3</div>
                <div className="stepName">Answers</div>
                <hr className="hrProperty" />
                <div className="stepDes">
                  Get Instant, Clear & Accurate Answers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-block"
        style={{
          background: "#08B180",
          color: "#FFF",
          fontFamily: "Work Sans",
          lineHeight: "18px",
          fontSize: "16px",
          fontWeight: 600,
          cursor: "pointer",
          height: "49px",
          marginTop: "65px",
        }}
        onClick={handleStartClick}
      >
        Book a Free Demo
      </button>
    </div>

    <div
      className="sec4"
      style={{
        height: "100%",
        paddingBottom:'100px'
      }}
    >
      <div
        style={{
          fontFamily: "Bebas Neue",
          fontSize: "60px",
          fontWeight: 700,
          lineHeight: "64px",
          textAlign: "center",
          color: "#FFFFFF",
          paddingTop: "120px",
        }}
      >
        Portfolio One Is Your{" "}
        <span
          style={{
            background: "#0A78FF",
            color: "#fff",
            display: "inline-block",
          }}
        >
          Strategic Co-Pilot
        </span>
      </div>
      <div
        style={{
          fontFamily: "Bebas Neue",
          fontSize: "60px",
          fontWeight: 700,
          lineHeight: "64px",
          textAlign: "center",
          color: "#9D9D9D",
          paddingBottom: "100px",
        }}
      >
        for physical asset Management
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "100px",
        }}
      >
        <div>
          <img src={mask1} />
        </div>
        <div
          style={{
            textAlign: "left",
            width: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={box}
            style={{ height: "64px", width: "64px", marginBottom: "24px" }}
          ></img>
          <div
            style={{
              fontFamily: "Work Sans",
              fontSize: "32px",
              fontWeight: 500,
              lineHeight: "38px",
              letterSpacing: "-0.05em",
              textAlign: "left",
              color: "#fff",
              paddingBottom: "24px",
            }}
          >
            Get A Clear View of Your <div>Real Estate Portfolio</div>
          </div>
          <div
            style={{
              fontFamily: "Work Sans",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "-0.05em",
              textAlign: "left",
              color: "#9D9D9D",
              paddingBottom: "24px",
            }}
          >
            Our centralized platform offers comprehensive insights into your
            current operations. From performance metrics to future
            projections, our advanced tools give you exactly the right data
            for informed decision-making.
          </div>

          <button
            className="btn"
            style={{
              display: "inline-block", // Ensures button width matches content
              color: "#FFF",
              padding: "15px 16px", // Provides necessary padding around text
              fontFamily: "Work Sans",
              lineHeight: "18px",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
              border: "1px solid #282828",
              borderRadius: "8px",
              backgroundColor: "transparent",
              whiteSpace: "nowrap",
              width: "130px",
            }}
            onClick={handleStartClick}
          >
            Get Started
          </button>
        </div>
      </div>

      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "100px",
          paddingTop: "55px",
        }}
      >
        <div>
          <img src={mask2} />
        </div>
        <div
          style={{
            textAlign: "left",
            width: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={eye}
            style={{ height: "64px", width: "64px", marginBottom: "24px" }}
          ></img>
          <div
            style={{
              fontFamily: "Work Sans",
              fontSize: "32px",
              fontWeight: 500,
              lineHeight: "38px",
              letterSpacing: "-0.05em",
              textAlign: "left",
              color: "#fff",
              marginBottom: "24px",
            }}
          >
            Predict The Future <div>with Accuracy</div>
          </div>
          <div
            style={{
              fontFamily: "Work Sans",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "-0.05em",
              textAlign: "left",
              color: "#9D9D9D",
              marginBottom: "24px",
            }}
          >
            Our advanced forecasting tools help you anticipate trends and
            create various scenarios for Senior Leaders to make strategic
            decisions that drive long-term success.
          </div>
          <button
            className="btn"
            style={{
              display: "inline-block", // Ensures button width matches content
              color: "#FFF",
              padding: "15px 16px", // Provides necessary padding around text
              fontFamily: "Work Sans",
              lineHeight: "18px",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
              border: "1px solid #282828",
              borderRadius: "8px",
              backgroundColor: "transparent",
              whiteSpace: "nowrap",
              width: "130px",
            }}
            onClick={handleStartClick}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>

    <div
      className="sec5"
      style={{
        height: "100%",
        paddingBottom:'100px'
      }}
    >
      <div
        style={{
          fontFamily: "Bebas Neue",
          fontSize: "60px",
          fontWeight: 700,
          lineHeight: "64px",
          textAlign: "center",
          color: "#FFFFFF",
          paddingTop: "200px",
        }}
      >
        Features That{" "}
        <span
          style={{
            background: "#0A78FF",
            color: "#fff",
            display: "inline-block",
          }}
        >
          Set Us Apart
        </span>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          paddingTop: "65px",
        }}
      >
        <div className="card cardType2">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "250px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card1} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              Resource Demand Forecasting
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Our advanced algorithms predict real estate demand, helping you
              anticipate future needs.
            </div>
          </div>
        </div>

        <div style={{ textAlign: "left" }}>
          <div className="card cardType2">
            <div className="card-body">
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#0E0E0E",
                  height: "250px",
                  backgroundImage: `url(${swatch})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "17px",
                  border: "1px solid #232323",
                }}
              >
                <img src={card2} />
              </div>
              <div
                style={{
                  fontFamily: "Work Sans",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#fff",
                  letterSpacing: "-5%",
                  marginTop: "34px",
                  marginBottom: "12px",
                  textAlign: "left",
                }}
              >
                Portfolio Optimization
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#9D9D9D",
                  letterSpacing: "-5%",
                }}
              >
                Receive personalized recommendations to optimize your real
                estate for cost-effectiveness, location, and resource
                availability.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="card cardType3">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "165px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card3} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              Intuitive Data Visualization
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Gain valuable insights with our intuitive charts, graphs, and
              maps that bring market trends and portfolio performance to life.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "165px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card4} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              Customizable Dashboards
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Personalize your dashboard to your specific needs, KPIs and OKRs
              for streamlined decision-making.
            </div>
          </div>
        </div>
        <div className="card cardType3">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "165px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card5} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              Collaboration Tools
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Share insights, analysis, and reports seamlessly with your team,
              fostering collaboration and alignment.
            </div>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="card cardType2">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "250px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card6} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              Hassle-Free Integration
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Seamlessly integrate Portfolio One with your existing enterprise
              systems for efficient data management.
            </div>
          </div>
        </div>
        <div className="card cardType2">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#0E0E0E",
                height: "250px",
                backgroundImage: `url(${swatch})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "17px",
                border: "1px solid #232323",
              }}
            >
              <img src={card7} />
            </div>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#fff",
                letterSpacing: "-5%",
                marginTop: "34px",
                marginBottom: "12px",
                textAlign: "left",
              }}
            >
              100% Data Security and Compliance
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#9D9D9D",
                letterSpacing: "-5%",
              }}
            >
              Rest assured that your sensitive information is safeguarded with
              our strict security measures and compliance standards.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="sec6" style={{ height: "100%", paddingTop: "120px" }}>
      <div
        style={{
          display: "flex",
          height: "575px",
          alignItems: "center", // Centers content vertically
          backgroundImage: `url(${circles})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#111111",
        }}
      >
        <img
          src={hand2}
          alt=""
          style={{ marginTop: "-120px", height: "700px" }}
        />
        <div
          style={{
            fontSize: "42px",
            color: "#fff", // Ensures text is visible on dark background
            padding: "0 20px", // Adds some padding for better readability
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontFamily: "Work Sans",
              lineHeight: "56px",
              fontSize: "42px",
              fontWeight: 400,
              color: "#9D9D9D",
              letterSpacing: "-0.075em",
              marginBottom: "10px",
            }}
          >
            Optimize Your Physical Asset Strategy
          </div>
          <div
            className="rounded-pill px-2"
            style={{
              textAlign: "left",
              background: "#0A78FF",
              fontFamily: "Work Sans",
              lineHeight: "56px",
              fontSize: "42px",
              fontWeight: 400,
              color: "#fff",
              letterSpacing: "-0.075em",
              margin: 0,
              padding: 0,
            }}
          >
            with Smart AI-Powered Analytics.
          </div>
          <div
            style={{
              textAlign: "left",
              fontFamily: "Work Sans",
              lineHeight: "29px",
              fontSize: "24px",
              fontWeight: 500,
              color: "#FFF",
              letterSpacing: "-0.050em",
              marginTop: "35px",
            }}
          >
            Do More, with Portfolio One.
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "150px",
          marginBottom: "60px",
          fontFamily: "Work Sans",
          lineHeight: "56px",
          fontSize: "42px",
          fontWeight: 400,
          color: "#FFF",
          letterSpacing: "-0.075em",
        }}
      >
        Get in Touch
      </div>
      <div id="zohoform"></div>
      <form
        id="webform5957627000004610001"
        onSubmit={handleSubmit}
        name="WebToLeads5957627000004610001"
      >
        <input
          type="text"
          style={{ display: "none" }}
          name="xnQsjsdp"
          defaultValue="7bb935520832322811b7e9ac6aee49bdd670a0c3b801c8365e2f7a811d356320"
          readOnly
        />
        <input type="hidden" name="zc_gad" id="zc_gad" defaultValue=""></input>
        <input
          type="text"
          style={{ display: "none" }}
          name="xmIwtLD"
          defaultValue="55f3a6fbc602887c4c636a3056ebf82567bafe9a9ae5ca2d1308ab94535657d2973b1f7470a0334469083564134321b8"
          readOnly
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="actionType"
          defaultValue="TGVhZHM="
          readOnly
        />
       
        <input
          type="text"
          style={{ display: "none" }}
          id="ldeskuid"
          name="ldeskuid"
          readOnly
        />
        <input
          type="text"
          style={{ display: "none" }}
          id="LDTuvid"
          name="LDTuvid"
          value={uniqueId}
          readOnly
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
          }}
        >
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              First Name <span style={{ color: "#08B180" }}>*</span>
            </div>

            <input
              type="text"
              style={{
              width: "600px"
              }}
              className="form-control inputInitialForm"
              id="First Name"
              name='First Name'
              value={formValues.firstName}
              onChange={handleChange}
            />
            <div></div>
          </div>
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Last Name <span style={{ color: "#08B180" }}>*</span>
            </div>

            <input
              type="text"
              style={{
               width: "600px"
              }}
              className="form-control inputInitialForm"
              id="Last Name"
              name='Last Name'
              value={formValues.lastName}
              onChange={handleChange}
            />
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Email <span style={{ color: "#08B180" }}>*</span>
            </div>

            <input
              type="text"
              style={{
                width: "600px"
              }}
              className="form-control inputInitialForm"
              id="Email"
              name='Email'
              value={formValues.email}
              onChange={handleChange}
            />
            <div></div>
          </div>
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Phone
            </div>

            <input
              type="text"
              style={{
                width: "600px"
              }}
              className="form-control inputInitialForm"
              id="Phone"
              name='Phone'
              value={formValues.phone}
              onChange={handleChange}
            />
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Company <span style={{ color: "#08B180" }}>*</span>
            </div>

            <input
              type="text"
              style={{
               width: "600px"
              }}
              className="form-control inputInitialForm"
              id="Company"
              name='Company'
              value={formValues.company}
              onChange={handleChange}
            />
            <div></div>
          </div>
          <div style={{ width: "600px", textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Job Title <span style={{ color: "#08B180" }}>*</span>
            </div>

            <input
              type="text"
              style={{
                width: "600px"
              }}
              className="form-control inputInitialForm"
              id="Designation"
              name='Designation'
              value={formValues.designation}
              onChange={handleChange}
            />
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
                color: "#FFF",
                letterSpacing: "-5%",
              }}
            >
              Message
            </div>

            <textarea
              style={{
                width: "1250px",
                marginTop: "15px",
                height: "62px",
                border: "solid 1px #252525",
                borderRadius: "6px",
                backgroundColor: "#0D0D0D",
                padding: "19px 24px",
                color: "#fff",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
              className="form-control"
              id="Description"
              name='Description'
              value={formValues.Description}
              onChange={handleChange}
              rows={3}
            />
            <div></div>
          </div>
        </div>
        <div
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ textAlign: "left" }}>

          <div className="zcwf_row">
            <div className="zcwf_col_fld">
              <img id="captchaImage" src='https://crm.zoho.com/crm/CaptchaServlet?formId=55f3a6fbc602887c4c636a3056ebf82567bafe9a9ae5ca2d1308ab94535657d2973b1f7470a0334469083564134321b8&grpid=7bb935520832322811b7e9ac6aee49bdd670a0c3b801c8365e2f7a811d356320' alt="Captcha" />
              <LuRefreshCcw onClick={reloadCaptcha} style={{marginLeft:'20px', color:'#08B180', cursor:'pointer',  
      height: "30px", 
      width: "30px"}}/>
              
            </div>
          </div>
           
          </div>
  
        </div>
        <div
          style={{
            display: "none",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <div style={{ textAlign: "left" }}>

        <input
              style={{
                width: "300px",
                marginTop: "15px",
                height: "62px",
                border: "solid 1px #252525",
                borderRadius: "6px",
                backgroundColor: "#0D0D0D",
                padding: "19px 24px",
                color: "#393939",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "24px",
              }}
              className="form-control inputInitialForm"
              type='text'
              id="enterdigest"
              aria-labelledby="reCaptchaField"
              placeholder="Enter the Captcha"
                aria-valuemax={10}
                maxLength={10}
                name="enterdigest"
                value={captcha}
                onChange={handleInputChange}
            />
            </div>
            </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
            marginTop: "20px",
          }}
        >
          <button
            type="submit"
            id="formsubmit"
            value="Submit"
            aria-label="Submit"
            title="Submit"
            className="btn btn-block"
            style={{
              background: "#08B180",
              color: "#FFF",
              fontFamily: "Work Sans",
              lineHeight: "24px",
              fontSize: "18px",
              fontWeight: 600,
              cursor: "pointer",
              height: "49px",
              width: "170px",
            }}
            disabled={disableButton}
          >
            Submit
          </button>
        </div>
        <div
          style={{
            display: "flex",
            paddingLeft: "64px",
            paddingRight: "64px",
            marginTop: "100px",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={whitelogo} alt="" style={{ height: "21px" }} />
            <div
              style={{
                marginLeft: "20px", // Space between the logo and Privacy Policy
                fontFamily: "Work Sans",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "-0.05em",
                color: "#9D9D9D",
                paddingLeft: "100px",
                cursor:'pointer'
              }}
              onClick={handleShow}
            >
              Privacy Policy
            </div>
          </div>

          <div style={{ marginLeft: "auto", textAlign: "right" }}>
            <img src={linkedin} alt="" />
          </div>
        </div>
        <div
          style={{
            paddingLeft: "64px",
            paddingRight: "64px",
            marginTop: "40px",
          }}
        >
          <hr style={{ color: "#9D9D9D", border: "solid 0.5px" }} />
        </div>
        <div
          style={{
            fontFamily: "Work Sans",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#9D9D9D",
            letterSpacing: "-5%",
            paddingBottom:'40px'
          }}
        >
          Copyright © 2023 Digital Finance Transformation and contributors
        </div>
{/*         <script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=2e28fa9e48636681b52bbbdffdfce82ecdb2bfa9063e14d5f7fbf20bb8aaf6de3f2adbd143072ddc37aa79307b1246c8gid22177ee2bfc97ffefe7a0fbbc885ca7afd99d332cca4fb5926ff7d91bc985142gid6ee2438d954908a336338d8d580fa82a1f0751d52135df5c666b2e813b652becgidc1abf06de2014fa7737bb781fdb57e76c88fe5c6b00c1175a5c9308918ebce5b&tw=611731083142a05e2b7a00c02b810fbb418d18c0a6ef82427015d744305fcdee'></script>
 */}       
 {/*  <script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=6e7f1f541b192dad98730ec5b61e8a1a2e37e3b68bbce78b300cc722520495ae5c4b6a0cf2f3d6527e7f83c857662e63gid175946c18a74e0ba369e0223ed1440117cba095cb4c30c290d25a88f5f84284cgid247f794b5aa6c59b67730338bb227731f7aea1919e421eadce75933941055a95gidc5942e066d276ab00bbfba2733dc6e2f509d7b16cfb88ad70633a0ccdcbb8c26&tw=c26e9295dfee170ff00fe58031775f74ad40a9c04b951f324f021fe5ba58b3fc'></script>
       */}
      </form>
    </div>
    
    <Snackbar
    anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
    open={modalShow}
    autoHideDuration={5000}
    onClose={() => setModalShow(false)}
    message={digContent}
    ContentProps={{
      className: 'notifyBar1'
    }}
    key={'top' + 'center'}
    style={{marginTop:'100px'}}
  />

<Privacypolicy closeModal={handleClose} openModal={showModal} />
  </>
  );
};

export default Introduction;
